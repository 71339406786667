
.container {
  @media (min-width: 1366px) {
    max-width: 1280px;
  }

  @media (min-width: 1540px) {
    max-width: 1500px;
  }
}
